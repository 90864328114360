<template>
  <div class="container no_touch">
    <div class="text">绑定设备</div>
    
    <van-cell-group>
      <van-field v-model="imei" label="IMEI" type="digit" placeholder="请输入设备IMEI码" />
    </van-cell-group>

    <van-button style="margin-top: 20px" type="primary" size="large" @click="bind">完成绑定</van-button>

    <div class="text">请确保手环插卡并且处于开机状态，SIM有效并且开通了gprs业务。</div>
  
  </div>
</template>

<script>

import Vue from 'vue'
import {Field} from "vant";
import {getCMCC, postCMCCNormal} from "@/router/httpUtils";
import {Toast} from "vant";

Vue.use(Field)

export default {
  name: 'BindIMEI',
  data() {
    return {
      imei: ''
    }
  },
  methods: {
    bind() {

      this.getToken()
      // this.bindDevice()
    },
    getToken() {
      getCMCC('/thirdparty/token', {
        'appId': 'ketron',
        'secret': '25f0ff989f5b782bf8f7ccf89bd5eeea',
        'grant_type': 'client_credential'
      })
          .then(res => {
            console.log(res);
            var token = res.access_token
            this.bindDevice(token)
          })
          .catch(err => {
            console.log(err);
            Toast(err);
          })
    },
    bindDevice(token) {
      postCMCCNormal('/device/bind?access_token=' + token + '&sequence_no=' + (new Date()).valueOf() + '&deviceId=' + this.imei,)
          .then(res => {
            console.log(res);
            if (res.code == 0) {
              var url = '?deviceid=' + this.imei
              window.location.href= window.location.href.replace('bindIMEI', url)
            } else {
              Toast(res.msg)
            }
          })
          .catch(err => {
            console.log(err);
            Toast(err);
          })
    }
  }
}
</script>
<style scoped>
.container {
  padding-left: 5%;
  padding-right: 5%;
}
.text {
  color: gray;
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>